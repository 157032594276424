export default {
  // 数字过滤
  number(value) {
    value = value.replace(/[^\d]/g, '');
    if(value.length > 0) {
      value = parseFloat(value).toString();

      if (value === '0') {
        return '';
      }
    }
    return value;
  },
  noCn(value) {
    return value.replace(/[\u4e00-\u9fa5]/g, '')
  },
  // 过滤金额
  amount(value) {
    value = value.replace(/[^0-9.]/g, '')
    // 结果保留2位小数
    let amount = parseFloat(value).toString();
    if (amount.indexOf('.') > 0) {
      amount = amount.substring(0, amount.indexOf('.') + 3);
    }
    return amount;
  },
  // 仅限数字
  numberOnly(value) {
    return value.replace(/[^0-9]/g, '');
  },
  // 仅限数字和字母
  numberLetterOnly(value) {
    return value.replace(/[^0-9a-zA-Z]/g, '');
  },
  // 仅限字母空格
  letterSpaceOnly(value) {
    return value.replace(/[^a-zA-Z\s]/g, '');
  },
  // 仅限数字和字母和空格
  numberLetterSpaceOnly(value) {
    return value.replace(/[^0-9a-zA-Z\s]/g, '');
  },
  // 越南语
  vnOnly(value) {
    // return value.replace(/[^\u4e00-\u9fa5]/g, '')
    return value.replace(/[^\u0000-\u1EFF]/g, '')
  },
  // 金额正则筛选
  // 过滤金额
  amountExg(value) {
    if (value === undefined || value === null) {
      return value;
    }
    // 如果是小数点结尾，则返回
    if (value.length > 1 && value.indexOf('.') === value.length-1) {
      return value;
    }
    if (/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/.test(value)) {
      return value;
    } else {
      // 结果保留2位小数
      return this.amount(value);
    }
  },
  outputAmount(numStr, fixed) {
    let thousandSymbol = ',';
    let decimalSymbol = '.';
    
    var num = parseFloat(numStr);
    if(fixed) {
      num = num.toFixed(fixed);
    } else if(fixed === 0){
      num = Math.floor(num);
    }
    var amountList = num.toString().split('.');
    var returnAmount = amountList[0].replace(/(\d)(?=(?:\d{3})+$)/g, `$1${thousandSymbol}`);
    if(amountList.length == 2) {
      returnAmount = returnAmount + decimalSymbol + amountList[1];
    }
    return returnAmount;
  },
  upperCaseFirstLetter(string) {
    return string.toLowerCase().replace(/\b[a-z]/g, function(match) {
      return match.toUpperCase();
    });
  }
}