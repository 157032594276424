import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "common-rewards-popup" }
const _hoisted_2 = { class: "popup-main" }
const _hoisted_3 = { class: "popup-content" }
const _hoisted_4 = { class: "reward-title" }
const _hoisted_5 = { class: "common-rewards" }
const _hoisted_6 = { class: "reward-amount" }

import { ref, onMounted } from 'vue';
import { useHelpTool, useAudio} from '@/hooks';
import { useAccountStore, usePopupStore } from '@/store';


export default {
  __name: 'Reward',
  emits: ['onClose'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const { playAudio } = useAudio();
const { backgroundByTheme, imageByTheme } = useHelpTool()

const accountStore = useAccountStore();
const popupStore = usePopupStore();
const { rewards } = popupStore;

onMounted(() => {
  playAudio('open');
  accountStore.fetchBalance();
})

return (_ctx, _cache) => {
  const _component_van_image = _resolveComponent("van-image")
  const _component_van_popup = _resolveComponent("van-popup")
  const _directive_audio = _resolveDirective("audio")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: true,
      "close-on-click-overlay": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createVNode(_component_van_image, {
            class: "close-icon",
            src: require('@/assets/img/common/close.png'),
            onClick: _cache[0] || (_cache[0] = $event => (emit('onClose')))
          }, null, 8, ["src"]), [
            [_directive_audio, void 0, "close"]
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('reward.congratulation').toUpperCase()), 1),
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(rewards), (item) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["reward-item", { single: _unref(rewards).length === 1 }]),
                  onClick: _cache[1] || (_cache[1] = $event => (emit('onClose')))
                }, [
                  _createVNode(_component_van_image, {
                    class: "reward-icon",
                    fit: "contain",
                    src: require(`@/assets/img/common/reward/type/${item.type}.png`)
                  }, null, 8, ["src"]),
                  _createElementVNode("div", _hoisted_6, "+" + _toDisplayString(item.amount), 1)
                ], 2)), [
                  [_directive_audio, void 0, "close"]
                ])
              }), 256))
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", {
              class: "collect-btn",
              onClick: _cache[2] || (_cache[2] = $event => (emit('onClose')))
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('common.collect')), 1)
            ])), [
              [_directive_audio]
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}
}

}