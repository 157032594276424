import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { useRoute, useRouter } from 'vue-router'


export default {
  __name: 'NavBar',
  props: ['title', 'showHelp', 'hideBack'],
  emits: ['onClickHelp'],
  setup(__props, { emit: __emit }) {


const emit = __emit

const route = useRoute()
const router = useRouter()

const goBack = () => {
  if(route.name === 'login' || route.name === 'register') {
    router.replace({ name: 'game' })
  // } else if (route.name === 'forgetPassword') {
  } else {
    router.back()
  } 
}


return (_ctx, _cache) => {
  const _component_van_image = _resolveComponent("van-image")
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")
  const _directive_audio = _resolveDirective("audio")

  return (_openBlock(), _createBlock(_component_van_nav_bar, {
    title: __props.title,
    border: false
  }, {
    left: _withCtx(() => [
      (!(__props.hideBack === true || __props.hideBack === ''))
        ? _withDirectives((_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "back-btn flex justify-center",
            onClick: goBack
          }, [
            _createVNode(_component_van_image, {
              class: "back-icon",
              src: require('@/assets/img/common/back.png')
            }, null, 8, ["src"])
          ])), [
            [_directive_audio, void 0, "close"]
          ])
        : _createCommentVNode("", true)
    ]),
    right: _withCtx(() => [
      (__props.showHelp != undefined)
        ? _withDirectives((_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "help-btn",
            onClick: _cache[0] || (_cache[0] = $event => (emit('onClickHelp')))
          }, [
            _createVNode(_component_van_image, {
              class: "help-icon",
              src: require('@/assets/img/common/question.png')
            }, null, 8, ["src"])
          ])), [
            [_directive_audio]
          ])
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}
}

}